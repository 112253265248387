<template>
    <div class="col-xl-2 col-lg-2 col-md-2">        
        <div class="single-features mb-70 flex-column">
            <div class="row">
                <div class="col">
                    <div class="features-icon">
                        <img class="img-invert" :src="img">

                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="features-caption">
                        <h3 style="color: #fff; font-size: 1em; margin-top: 5px;">{{ title }}</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['img', 'title'],
    name: 'AboutCapacity',
}
</script>

<style scoped>
</style>