<template>
    <section class="best-features-area pt-50 pb-50 bg-about-cap">
        <div class="container text-center">
            <div class="row justify-content-center">
                <div class="col">
                    <div class="row">
                        <div style="position: relative;">
                            <h1 class="text d-none">
                                Desenvolvido por Doutores em Neurociências, 
                                Psicologia, Fisioterapia e profissionais da 
                                Educação Física
                            </h1>
                            <img src="@/assets/img/overlay/floating-brain-circled-text.png" alt="" class="aoverlayImg" height="350">
                        </div>
                    </div>
                </div>
                <!--<div class="col-xl-4 col-lg-4  align-self-center d-xs-none">
                    <div class="row">
                         <img src="@/assets/img/brain.png" class="img-fluid " alt=""> 
                    </div>
                </div>-->
            </div>
            <div class="row justify-content-center">
                
                <div class="col">
                    <!-- Section Tittle -->

                    <!-- Section caption -->
                    <div class="row">
                        <div class="col mb-5">
                            <div class="section-tittle">
                                <h3 class="textstyleSize1" >
                                    Mais de <span class="textstyleHighlightedYellow" >60 JOGOS COM ESTIMULAÇÕES COGNITIVAS</span> 
                                    
                                </h3>
                                <h3 class="textstyleSize2" >
                                    com ou sem movimento para desenvolvimento de capacidades como:
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <!-- <AboutCapacities/> -->
                        <AboutCapacitiy :img="require('@/assets/img/capacity/001-goal-circled-64.png')" title="Atenção"/>
                        <AboutCapacitiy :img="require('@/assets/img/capacity/002-brain-circled-64.png')" title="Tomada de decisão"/>
                        <AboutCapacitiy :img="require('@/assets/img/capacity/003-idea-circled-64.png')" title="Raciocínio"/>
                        <AboutCapacitiy :img="require('@/assets/img/capacity/004-memory-circled-64.png')" title="Memória"/>
                        <AboutCapacitiy :img="require('@/assets/img/capacity/005-warning-circled-64.png')" title="Controle da impulsividade"/>
                        <AboutCapacitiy :img="require('@/assets/img/capacity/006-checked-circled-64.png')" title="Reação"/>
                        
                        
                    </div>
                </div>
            </div>
        </div>        
    </section>
</template>

<script>

import AboutCapacitiy from '../elements/about/AboutCapacity.vue';
import AboutCapacitiesJson from '../../json/capacities.json'


export default {
  name: 'AboutMoovePage',
  components: {
    AboutCapacitiy,        
  },

  data(){
        return {
            AboutCapacitiesJson: AboutCapacitiesJson
        }
    },

    methods: {
        handleScroll(){            
            document.title='Sensorial Moove Trainer | Sobre';
        }
    },
   
    mounted: function () {
        let pageElement  = document.querySelector('.best-features-area');
        pageElement.addEventListener('mouseenter', this.handleScroll, {once : false});
    },
    unmounted: function () {
        let pageElement  = document.querySelector('.best-features-area');
        pageElement.removeEventListener('mouseenter', this.handleScroll, {once : false});
    }
}
</script>

<style scoped>
.text{
    color: whitesmoke;    
}

.aoverlayImg {
        /*position: absolute; 
        right: 20px;
        top: 16px;*/
        max-width: 100%;  
        height: auto;
        margin-bottom: 15px;
} 

.textstyleHighlightedYellow{
    color: #24597f;
    background-color: #e7e01e;
    border-radius: 20px;
    padding: 5px;
    font-size: 1.1em;
    font-weight: bold;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
}

@media screen and (max-width: 575px){
    .textstyleSize1{
        font-size: 0.9em;
        line-height: 1.5;
    }

    .textstyleSize2{
        font-size: 0.9em;
    }
}

@media screen and (min-width: 576px) and (max-width: 767px){
    .textstyleSize1{
        font-size: 1.3em;
    }

    .textstyleSize2{
        font-size: 1.3em;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px){
    .textstyleSize1{
        font-size: 1.3em;
    }

    .textstyleSize2{
        font-size: 1.25em;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px){
    .textstyleSize1{
        font-size: 1.7em;
    }

    .textstyleSize2{
        font-size: 1.625em;
    }
}

@media screen and (min-width: 1200px){
    .textstyleSize1{
        font-size: 2.1em;
    }

    .textstyleSize2{
        font-size: 2.0em;
    }
}

</style>