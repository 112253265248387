<template>
    <form class="forms" @submit.prevent="submit" method="post" id="frm-register" novalidate="novalidate">

        <div class="form-group " ref="formTop">
            <label for="name"><span class="required-label">*</span>Nome Completo:</label>
            <input name="name" type="text" class="form-control" required v-model="userData.name" >
            <div class="msg-validate">{{validationErrorBag.errorName}}</div>
        </div>


        <div class="form-group ">
            <label for="document"><span class="required-label">*</span>CPF: <i class="fa fa-question-circle" data-tooltip="" title="Pedimos o CPF para emissão da sua Nota Fiscal"></i></label>
            <input name="document" type="text" class="form-control" required v-model="userData.document" v-mask="'###.###.###-##'" autocomplete="off" maxlength="14">
            <div class="msg-validate">{{validationErrorBag.errorDocument}}</div>
        </div>

        <div class="form-group ">
            <label for="email"><span class="required-label">*</span>E-mail:</label>
            <input name="email" type="text" class="form-control" required v-model="userData.email">
            <div class="msg-validate">{{validationErrorBag.errorEmail}}</div>
        </div>


        <div class="form-group ">
            <label for="password"><span class="required-label">*</span>Senha: <i :class="passwordIcon" :title="passwordTooltip" style="cursor: pointer" @click="switchVisibility"></i></label>
            <input name="password" :type="passwordFieldType" class="form-control" required v-model="userData.password">            
            <div class="msg-validate">{{validationErrorBag.errorPassword}}</div>
        </div>

        <div class="form-group ">
            <div style=" float: left;  display: flex; ">
                <label for="zip"><span class="required-label" >*</span>CEP: </label>
                <ClipLoader :loading="loadingSpin" :color="'#e8e221'" :size="'20px'" style="    margin-left: 5px;"/>
            </div>            
            <input name="zip" type="text" class="form-control" required v-model="userAddress.zip" v-mask="'#####-###'" autocomplete="off" maxlength="10" @blur="getAddressByCep()">
            <div class="msg-validate">{{validationErrorBag.errorZip}}</div>
        </div>


        <div class="form-group ">
            <label for="address"><span class="required-label">*</span>Endereço:</label>
            <input name="address" type="text" class="form-control" required v-model="userAddress.address">
            <div class="msg-validate">{{validationErrorBag.errorAddress}}</div>
        </div>

        <div class="form-group ">
            <label for="number"><span class="required-label">*</span>Número:</label>
            <input name="number" type="text" class="form-control" required v-model="userAddress.number">
            <div class="msg-validate">{{validationErrorBag.errorNumber}}</div>
        </div>

        <div class="form-group ">
            <label for="complement">Complemento:</label>
            <input name="complement" type="text" class="form-control" required v-model="userAddress.complement">
            <div class="msg-validate">{{validationErrorBag.errorComplement}}</div>
        </div>

        <div class="form-group  ">
            <label for="neighborhood">Bairro:</label>
            <input name="neighborhood" type="text" class="form-control" required v-model="userAddress.neighborhood">
            <div class="msg-validate">{{validationErrorBag.errorNeighborhood}}</div>
        </div>
        <div class="form-group  ">
            <label for="city">Cidade:</label>
            <input name="city" type="text" class="form-control" required v-model="userAddress.city">
            <div class="msg-validate">{{validationErrorBag.errorCity}}</div>
        </div>
        <div class="form-group  ">
            <label for="state">UF:</label>
            <input name="state" type="text" class="form-control" required v-model="userAddress.state" maxlength="2">
            <div class="msg-validate">{{validationErrorBag.errorState}}</div>
        </div>


        <div class="form-group ">
            <label for="coupon">Cupom:</label>
            <input name="coupon" type="text" class="form-control coupon" v-model="userData.coupon">
            <div class="msg-validate">{{validationErrorBag.errorCoupon}}</div>
        </div>

        <div class="form-group">            
            <button class="btn card-btn1 w-100 mt-2" type="button" id="btn-register" :style="formButtonStyle" :disabled="isSubButtonDisabled" @click="submit()">
                <ClipLoader :loading="!isSubButtonDisabled" :color="'#e8e221'" :size="'35px'"  id="clipspin1" v-show="true" v-if="isSubButtonDisabled" style="margin-bottom: 25px;"/>
                <span v-if="!isSubButtonDisabled">Cadastrar e Assinar</span><span v-if="isSubButtonDisabled">Processando</span>
            </button>
        </div>
    </form>
</template>

<script>

import {mask} from 'vue-the-mask'
import { LoginApi } from '@/functions/LoginApi.js';
import { RegisterApi } from '@/functions/RegisterApi.js';

import { ZipCodeApi } from '@/functions/ZipCodeApi.js';
import { ClipLoader } from 'vue3-spinner';

export default{
    name: "SubRegisterForm",
    directives: {
        mask
    },

    components: { 
        ClipLoader 
    },

    created: function(){
        if(process.env.VUE_APP_DEFAULT_INPUTS_VALUES != "true"){
            let iteratedObject = this.userData;
            Object.keys(iteratedObject).map(function(k){                 
                iteratedObject[k] = '';                
            })

            iteratedObject = this.userAddress;
            Object.keys(iteratedObject).map(function(k){                 
                iteratedObject[k] = '';                
            })
        }
    },

    data: function () {
        return {
            userData: {
                name: "testename5",
                email: "testlptrainer5@test.com",
                confirm_email: "testlptrainer5@test.com",
                password: "Aa1234",
                document: "911.128.671-71",
                coupon: null,
            },
            userAddress: {
                zip: "14097010",
                address: "",
                number: "95",
                complement: "",
                city: "",
                state: "",
                neighborhood: "",
            },
            validationErrorBag: {
                errorName: "",
                errorEmail: "",
                errorPassword: "",
                errorCoupon: "",
                errorDocument: "",
                errorZip: "",
                errorAddress: "",
                errorNumber: "",
                errorComplement: "",
                errorCity: "",
                errorState: "",
                errorNeighborhood: ""
            },

            isSubButtonDisabled: false,
            loadingSpin: false,
            formButtonStyle: '',
            passwordTooltip: "Mostrar senha",
            passwordIcon: "fa fa-eye-slash",
            passwordFieldType: "password",
            registerApi: new RegisterApi(),
            loginApi: new LoginApi()
        };
    },

    methods: {

        submit() {
            console.log("submit");
            this.$parent.clearGlobalAlert();          
            this.toggleButtonBehavior();

            if (this.validation(this.registerApi.validateRegister(this.userData, this.userAddress, false))) {                
                console.log("validated");
                this.register();
            }            
            else{
                this.toggleButtonBehavior();                
                this.$parent.globalAlert = 'Corrija os campos abaixo';
                this.$parent.focusGlobalAlert();
            }            
        },


        validation: function (evaluation) {            
            console.log("validation");
            //console.log(evaluation._errorBag);
            
            this.registerApi.pupulateValidatedErrorBag(this, evaluation._errorBag);
            //console.log(this.validationErrorBag);
            return evaluation.isValid;            
        },

        async register() {
            console.log("register");
            
            if ((await this.storeTrainer())) {                
                console.log("stored trainer");
                //this.$parent.userId = 4703; //4704
                //this.login();
                if (this.login(await this.loginApi.doLogin(this.userData.email, this.userData.password))) {
                    console.log("logged");
                    if ((await this.dataStore())) {
                        console.log("stored trainer data");
                        this.$parent.subscription(this.userData.coupon);
                    }
                }
            }
            else {
                console.log("not stored trainer");                
            }
            
            this.toggleButtonBehavior();
            this.$parent.focusGlobalAlert();
        },
        
        async storeTrainer() {
            console.log("store trainer");

            let success;
            var trainerStoreResponse = await this.registerApi.doStoreTrainer(this.userData);

            //console.log(trainerStoreResponse);
            if (trainerStoreResponse._success === false) {
                this.$parent.globalAlert = trainerStoreResponse._error;
            }

            success = trainerStoreResponse._success;
            return success;            
        },

        async dataStore() {
            console.log("store trainer data");

            let success;
            var trainerDataStoreResponse = await this.registerApi.doDataStore(this.userData, this.userAddress, this.$parent.userId, this.$parent.userToken);

            //console.log(trainerDataStoreResponse);
            if (trainerDataStoreResponse._success === false) {
                this.$parent.globalAlert = trainerDataStoreResponse._error;
            }

            success = trainerDataStoreResponse._success;
            return success;            
        },

        login: function (loginResponse) {
            console.log("login");
            //console.log(loginResponse);

            let success;

            if (loginResponse._success === false) {
                this.$parent.globalAlert = loginResponse._error;
            }
            else {
                this.$parent.userId = loginResponse._userId;
                this.$parent.userToken = loginResponse._token;
                //this.$parent.subscription()
            }

            success = loginResponse._success;
            return success;            
        },

        async getAddressByCep() {
            this.toggleSpinnerShow();

            let zipCodeApi = new ZipCodeApi();
            let zipResponse = await zipCodeApi.doGetAddressByCep(this.userAddress.zip);

            //console.log(zipResponse);
            zipCodeApi.buildAddressViewObject(zipResponse, this);
            this.toggleSpinnerShow();                        
        },

        toggleButtonShowLoader: function () {
            this.isSubButtonDisabled = !this.isSubButtonDisabled;
        },

        toggleSpinnerShow: function () {
            this.loadingSpin = !this.loadingSpin;
        },

        toggleButtonStyle: function () {
            if(this.isSubButtonDisabled){
                this.formButtonStyle = {'pointer-events' : 'none'};                
            }
            else{
                this.formButtonStyle = '';                
            }
        },

        toggleButtonBehavior: function () {
            this.toggleButtonShowLoader();
            this.toggleButtonStyle();
        },

        switchVisibility: function () {            
            if (this.passwordFieldType === "password") {
                this.passwordFieldType = "text";
                this.passwordTooltip = "Esconder senha";
                this.passwordIcon = "fa fa-eye";
            }
            else {
                this.passwordFieldType = "password";
                this.passwordTooltip = "Mostrar senha";
                this.passwordIcon = "fa fa-eye-slash";
            }
        },
    }    
}
</script>