import { UrlHelper } from "./UrlHelper.js";

export class RegisterApi{

    
    urlHelper = new UrlHelper();
    mainApiURL= this.urlHelper.getMainApiURL();
    payApiURL = this.urlHelper.getPayApiURL();

    validateRegister(_userData, _userAddress, onlyUserDocumentData){
        console.log('validateRegister');     

        let _errorBag = {        
            _errorEmail : '',
            _errorPassword : '',
            _errorName : '',
            _errorDocument : '',
            _errorZip : '',
            _errorAddress : '',
            _errorNumber : '',
            _errorCity : '',
            _errorState : '',
            _errorNeighborhood : '',
            _errorCoupon: '',
            _errorComplement: '',
        }
                
        let isValid = true;
        let validatedUserData;

        if(onlyUserDocumentData){
            validatedUserData = this.validateUserDocumentData({isValid, _userData, _errorBag});
        }
        else{
            validatedUserData = this.validateUserData({isValid, _userData, _errorBag});
        }
        

        isValid = validatedUserData.isValid;
        _userData = validatedUserData._userData;
        _errorBag = validatedUserData._errorBag;
        

        let validatedUserAddress = this.validateUserAddress({isValid, _userAddress, _errorBag});
        isValid = validatedUserAddress.isValid;
        _userData = validatedUserAddress._userData;
        _errorBag = validatedUserAddress._errorBag;

        
        return {isValid, _errorBag};
    }

    validateUserData(dataToValidate){
        
        if(dataToValidate._userData.email == ''){
            dataToValidate._errorBag._errorEmail = 'O campo E-mail é obrigatório';
            dataToValidate.isValid = false;
        }

        if(dataToValidate._userData.password == ''){
            dataToValidate._errorBag._errorPassword = 'O campo Senha é obrigatório';
            dataToValidate.isValid = false;
        }

        if(dataToValidate._userData.name == ''){
            dataToValidate._errorBag._errorName = 'O campo Nome é obrigatório';
            dataToValidate.isValid = false;
        }

        if(dataToValidate._userData.document == ''){
            dataToValidate._errorBag._errorDocument = 'O campo CPF é obrigatório';
            dataToValidate.isValid = false;
        }

        return dataToValidate;
    }

    validateUserAddress(dataToValidate){
        if(dataToValidate._userAddress.zip == ''){
            dataToValidate._errorBag._errorZip = 'O campo CEP é obrigatório';
            dataToValidate.isValid = false;
        }

        if(dataToValidate._userAddress.address == ''){
            dataToValidate._errorBag._errorAddress = 'O campo Endereço é obrigatório';
            dataToValidate.isValid = false;
        }

        if(dataToValidate._userAddress.number == ''){
            dataToValidate._errorBag._errorNumber = 'O campo Número é obrigatório';
            dataToValidate.isValid = false;
        }

        if(dataToValidate._userAddress.city == ''){
            dataToValidate._errorBag._errorCity = 'O campo Cidade é obrigatório';
            dataToValidate.isValid = false;
        }

        
        dataToValidate._userAddress.state = dataToValidate._userAddress.state.toUpperCase();
        if(dataToValidate._userAddress.state == ''){
            dataToValidate._errorBag._errorState = 'O campo Estado é obrigatório';
            dataToValidate.isValid = false;
        }

        if(dataToValidate._userAddress.neighborhood == ''){
            dataToValidate._errorBag._errorNeighborhood = 'O campo Bairro é obrigatório';
            dataToValidate.isValid = false;
        }

        return dataToValidate;
    }

    validateUserDocumentData(dataToValidate){
        if(dataToValidate._userData.document == ''){
            dataToValidate._errorBag._errorDocument = 'O campo CPF é obrigatório';
            dataToValidate.isValid = false;
        }

        return dataToValidate;
    }



    initializeDefaultValues(objectToInitialize){
        
        let iteratedObject = objectToInitialize;
        Object.keys(iteratedObject).map(function(k){             
            iteratedObject[k] = '';
        })

        //console.log('pos iterate');
        //console.log(iteratedObject);        
        return iteratedObject;
    }


    pupulateValidatedErrorBag(parent, validatedRegisterErrorBag){

        //console.log('test iterate');        
        for (const key in parent.validationErrorBag){            
            parent.validationErrorBag[key] = validatedRegisterErrorBag['_'+key];            
        }       
    }

   
    async doStoreTrainer (userData){        
        console.log("dostoretrainer");

        let _success = false;
        let _userId = 0;
        let _error = '';
        
        await fetch(this.mainApiURL+'/api/v1/user/store/trainer', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                "name": userData.name,
                "email": userData.email,
                "confirm-email": userData.email,
                "password": userData.password,                                        
            })
        })
        .then(async (response) => {
            //console.log("then");
            var data = await response.json()
            if(response.status == 200){
                
                //console.log(data);
                _userId = data.id;
                _success = true;
                //console.log("200");                
            }
            else{
                throw data;
            }
        })
        .catch(error => {
            //console.log("catch");
            Object.keys(error).map(function(k){                      
                _error+= error[k][0]+" ";
            })
        })

        console.log("returned");
        return {_success, _userId, _error};
    }


    async doDataStore(userData, userAddress, userId, userToken){
        console.log("dodatastore");

        let _success = false;        
        let _error = '';

        //console.log(userToken +', '+userId);

        //'Access-Control-Allow-Origin': '*'
        //await fetch(this.payApiURL+'/api/v1/user/data/store', {
        await fetch(this.mainApiURL+'/api/v1/subscriptions/trainer/data/store', {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`
                
            },                
            body: JSON.stringify({ 
                "user_id": userId,
                "document": userData.document,
                "zip": userAddress.zip,
                "number": userAddress.number,
                "address": userAddress.address,
                "city": userAddress.city,
                "state": userAddress.state,
                "neighborhood": userAddress.neighborhood,
                "complement": userAddress.complement                    
            })
        })
        .then(async (response) => {
            //console.log("then");
            var data = await response.json()
            if(response.status == 200){                

                //console.log(data);
                _success = true;
                //console.log("200");
            }
            else{                
                throw data;
            }
        })
        .catch(error => {                        
            //console.log("catch");
            console.log(error);
            Object.keys(error).map(function(k){   
                if(k == 'document'){                    
                    _error+= "O campo CPF não é um CPF válido. ";
                }
                else{
                    _error+= error[k]+" ";
                }
            })
        })

        console.log("returned");
        return {_success, _error};
    }


}