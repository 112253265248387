import { UrlHelper } from "./UrlHelper.js";

export class ZipCodeApi{
    
    urlHelper = new UrlHelper();
    zipCodeURL= this.urlHelper.getZipURL();

    async doGetAddressByCep(zip){
        let _numberCep = zip.replace('-', '')
        let _errorZip = "";
        let _addressBag;

        // check if cep is valid
        if (_numberCep.length != 8){
            _errorZip = "CEP inválido";            
        }
        else{        
            // get address
            await fetch(this.zipCodeURL + _numberCep + '/json/')
            .then(async (response) => {
                // await for address response
                let queryAddress = await response.json();

                //check the response
                if("erro" in queryAddress){                                        
                    throw queryAddress;
                }

                _addressBag = queryAddress;
            })
            .catch(error => {
                console.log(error);            
                if("erro" in error){
                    _errorZip = "CEP inválido";
                }                                
            });
        }
        
        return {_errorZip, _addressBag};
    }

    buildAddressViewObject(zipResponse, parent){        
        
        
        if(zipResponse._errorZip == ''){
            parent.userAddress.address        = zipResponse._addressBag.logradouro != null ? zipResponse._addressBag.logradouro : parent.userAddress.address;
            parent.userAddress.number         = zipResponse._addressBag.number     != null ? zipResponse._addressBag.number     : parent.userAddress.number;
            parent.userAddress.zip            = zipResponse._addressBag.cep        != null ? zipResponse._addressBag.cep        : parent.userAddress.zip;
            parent.userAddress.neighborhood   = zipResponse._addressBag.bairro     != null ? zipResponse._addressBag.bairro     : parent.userAddress.neighborhood;
            parent.userAddress.city           = zipResponse._addressBag.localidade != null ? zipResponse._addressBag.localidade : parent.userAddress.city;
            parent.userAddress.state          = zipResponse._addressBag.uf         != null ? zipResponse._addressBag.uf         : parent.userAddress.state;
        }
        else{
            this.initializeDefaultUserAddressValues(false, parent);
        }

        parent.validationErrorBag.errorZip = zipResponse._errorZip;
        //console.log(parent.validationErrorBag.errorZip);
    }

    initializeDefaultUserAddressValues(shouldResetZip, parent){            
    
        let iteratedObject = parent.userAddress;
        
        Object.keys(iteratedObject).map(function(k){ 
            if(k == 'zip'){
                iteratedObject[k] = shouldResetZip == true ? '' : iteratedObject[k];
            }
            else{
                iteratedObject[k] = '';
            }
        })

        //console.log('pos iterate');
        //console.log(parent.userAddress);        
    }
}