<template>
    <footer>

        <!-- Footer Start-->
        <div class="footer-main ">
            <div class="footer-area footer-padding">
                <div class="container">

                    <div class="row  justify-content-between">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="single-footer-caption">
                                <!-- logo -->
                                <div class="footer-logo">
                                    <a href="/">
                                        <img src="@/assets/img/logo-w.png" alt="" class="img-fluid w-75">
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="single-footer-caption text-center ">
                                <div class="footer-tittle">
                                    <ul class="social-icons">
                                        <li>
                                            <a href="mailto:seuemail@dominio.com.br" target="_blank">
                                                <i class="far fa-envelope rounded-circle p-2"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="http://api.whatsapp.com/send?1=pt_BR&amp;phone=5501234567890" target="_blank">
                                                <i class="fab fa-whatsapp rounded-circle p-2"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="http://instagram.com/" target="_blank">
                                                <i class="fab fa-instagram rounded-circle p-2"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="http://facebook.com/" target="_blank">
                                                <i class="fab fa-facebook-f rounded-circle p-2"></i>
                                            </a>
                                        </li>
                                        <li class="d-none">
                                            <a href="http://twitter.com/" target="_blank">
                                                <i class="fab fa-twitter rounded-circle p-2"></i>
                                            </a>
                                        </li>
                                        <li class="d-none">
                                            <a href="http://youtube.com/" target="_blank">
                                                <i class="fab fa-youtube rounded-circle p-2"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row  justify-content-between">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="term">
                                <a href="http://sensorialsports.com/termos-de-uso"> Termo de uso e Políticas de
                                    Privacidade</a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- Footer End-->

    </footer>
</template>

<script>
export default {
  name: 'FooterPage',

  methods: {
        handleScroll(){            
            document.title='Sensorial Moove Trainer | Contato';
        }
    },
   
    mounted: function () {
        let pageElement  = document.querySelector('.footer-area');
        pageElement.addEventListener('mouseenter', this.handleScroll, {once : false});
    },
    unmounted: function () {
        let pageElement  = document.querySelector('.footer-area');
        pageElement.removeEventListener('mouseenter', this.handleScroll, {once : false});
    }
}
</script>

<style scoped>
.h1{
    color: #5e0404;
}
</style>


