import { UrlHelper } from "./UrlHelper.js";

export class LoginApi{
  
    urlHelper = new UrlHelper();
    mainApiURL= this.urlHelper.getMainApiURL();    

    validateLogin(email, password){
        console.log("validateLogin");        
        let _errorEmail = ''
        let _errorPassword = ''
        
        let isValid = true;
        
        
        if(email == '' ){
            _errorEmail = 'O campo E-mail é obrigatório';
            isValid = false;
        }

        if(password == ''){
            _errorPassword = 'O campo Senha é obrigatório';
            isValid = false
        }

        
        return {isValid, _errorEmail, _errorPassword};
    }

    async doLogin(email, password){
        console.log("dologin");
        let _email = email;
        let _password = password;
        let _success = false;

        let _userId = 0;
        let _token = '';
        let _error = '';
        
        await fetch(this.mainApiURL+'/api/v2/moove/users/login', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                email: _email,
                password: _password,
                big_screen: 1,
                guest: 1, 
                version: '2.0.0',   
            })
        })
        .then(async (response) => {
            //console.log("then");
            var data = await response.json();
            if(response.status == 200){                
                if(data.user.user_type == 4){                
                    _userId = data.user.id;
                    _token = data.access_token;             
                    _success = true;
                    //console.log("200");
                }
                else{
                    _error = 'Somente usuários treinadores podem realizar essa compra!';
                }
            }
            else{
                if(data.message.indexOf("Permission") != -1){
                    _error = 'Somente usuários treinadores podem realizar essa compra!';
                }
                else{                
                    _error = 'Usuário ou senha inválido';
                }
            }
        })
        .catch(error => {            
            _error = 'Erro ao fazer login, tente novamente mais tarde';
            console.log(error);
        })
        //console.log("returned");
        return {_success, _userId, _token, _error};
    }
}
