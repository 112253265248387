<template>
    <form class="forms" @submit.prevent="submit" method="post" id="frm-login" novalidate="novalidate">
        <div class="row justify-content-center">
        <div class="col col-sm-9 col-md-8 col-lg-6 col-xl-5">
            <div class="form-group">            
                <div style="position: relative;">
                    <input name="name" type="text" class="form-control formInputRounded" required v-model="name"  placeholder="Nome">
                    <img src="@/assets/img/overlay/form-input-overlay.png" alt="" class="overlayImg" height="16">
                    <div class="msg-validate">{{errorName}}</div>
                </div>
            </div>

            <div class="form-group ">
                <div style="position: relative;">
                    <input name="mail" type="email" class="form-control formInputRounded" required v-model="mail"  placeholder="E-mail">
                    <img src="@/assets/img/overlay/form-input-overlay.png" alt="" class="overlayImg" height="16">
                    <div class="msg-validate">{{errorMail}}</div>
                </div>
            </div>

            <div id="captcha" class="d-flex f-start g-recaptcha" data-sitekey="6Ldb2Z4aAAAAAMK94rlGuxhDKhI200saGxRZyrzL"></div>
            <div class="msg-validate"></div>

            <button class="btn card-btn1 w-100 mt-2" type="button" id="btn-login" :style="formButtonStyle" :disabled="isSubButtonDisabled" @click="submit()">
                <div class="row align-items-center justify-content-center">
                    <div class="col-3 col-sm-3 col-md-3 col-lg">
                        <ClipLoader :loading="!isSubButtonDisabled" :color="'#24597f'" :size="'35px'"  id="clipspin1" v-show="true" v-if="isSubButtonDisabled" style=""/>
                    </div>
                    <div class="col-3 col-sm-3 col-md-3 col-lg">
                        <span v-if="!isSubButtonDisabled">Envie!</span><span v-if="isSubButtonDisabled">Processando</span>
                    </div>
                    <div class="col-3 col-sm-3 col-md-3 col-lg" style="min-height: 35px;"></div>
                </div>
            </button>
        </div>
    </div>
    </form>
</template>

<script>

import { LoginApi } from '@/functions/LoginApi.js';
import { ClipLoader } from 'vue3-spinner';

export default{
    name: "SubLoginForm",
    created(){
        if(process.env.VUE_APP_DEFAULT_INPUTS_VALUES != "true"){
            this.email = '';
            this.password = '';
            this.coupon = '';
        }
    },

    components: {        
        ClipLoader
    },

    data: function(){
        return {
            email: 'convidado@sensorial.life',
            password: '0000',
            coupon: null,

            name: '',
            mail: '',
            
            errorName: '',

            errorMail: '',
            errorPassword: '',
            errorCoupon: '',
            
            isSubButtonDisabled: false,               
            formButtonStyle: {'pointer-events' : 'auto'},

            passwordTooltip: "Mostrar senha",
            passwordIcon: "fa fa-eye-slash",
            passwordFieldType: "password",
        }
    },

    methods: {

        async submit(){
            console.log("submit");
            this.$parent.clearGlobalAlert();
            this.toggleButtonBehavior();            
            var loginApi = new LoginApi();

            if(this.validation(loginApi.validateLogin(this.email, this.password))){
                this.login(await loginApi.doLogin(this.email, this.password));                
            }
            else{
                this.$parent.globalAlert = 'Corrija os campos abaixo';                
            }
            this.toggleButtonBehavior();            
            this.$parent.focusGlobalAlert();
        },
        
        validation: function(evaluation){
            console.log("validation");
            //console.log(evaluation);

            this.errorMail = "";
            this.errorPassword = "";            
            
            if(evaluation.isValid === false){
                this.errorMail = evaluation._errorEmail;
                this.errorPassword = evaluation._errorPassword;
            }

            return evaluation.isValid;            
        },

        login: function(loginResponse){
            console.log("login");
            //console.log(loginResponse);

            let success;

            if(loginResponse._success === false){                
                this.$parent.globalAlert = loginResponse._error;
            }
            else{
                this.$parent.userId = loginResponse._userId;
                this.$parent.userToken = loginResponse._token;
                
                this.$parent.showUserDataModal()
            }

            success = loginResponse._success;
            return success;
        },

        toggleButtonShowLoader: function () {
            this.isSubButtonDisabled = !this.isSubButtonDisabled;
        },

        toggleButtonStyle: function () {
            if(this.isSubButtonDisabled){
                this.formButtonStyle['pointer-events'] = 'none';
            }
            else{       
                //let pageElement  = document.querySelector('.formButtonEnviar');
                //console.log(pageElement.getAttribute("style"));
                this.formButtonStyle['pointer-events'] = 'auto';
                //pageElement.setAttribute()
                
            }
        },

        toggleButtonBehavior: function () {
            this.toggleButtonShowLoader();
            this.toggleButtonStyle();
        },

        switchVisibility: function () {            
            if (this.passwordFieldType === "password") {
                this.passwordFieldType = "text";
                this.passwordTooltip = "Esconder senha";
                this.passwordIcon = "fa fa-eye";
            }
            else {
                this.passwordFieldType = "password";
                this.passwordTooltip = "Mostrar senha";
                this.passwordIcon = "fa fa-eye-slash";
            }
        },
    }
}
</script>

<style scoped>
    input::placeholder {
        color: #fff !important; 
        font-size: 1.2em;
        font-style: normal;
        opacity: 0.8 !important;
    }

    .overlayImg {
        position: absolute; 
        right: 20px;
        top: 16px;
        max-width: 100%;  
        height: auto;
    }   

    input:focus + .overlayImg {
        opacity: 0;
    }


</style>