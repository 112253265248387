export class UrlHelper{    
    
    zipCodeURLfromEnv  = '';
    mainApiURLfromEnv  = '';
    payApiURLfromEnv   = '';
    
    constructor() {
        this.zipCodeURLfromEnv  = '';
        this.mainApiURLfromEnv  = '';
        this.payApiURLfromEnv   = '';
    }


    getZipURL(){        
        this.zipCodeURLfromEnv  = process.env.VUE_APP_ZIP_ADD_URL;

        return this.zipCodeURLfromEnv;
    }

    getMainApiURL(){
        if(process.env.VUE_APP_DEV_MODE == "true"){
            this.mainApiURLfromEnv  = process.env.VUE_APP_MAIN_DEV_API_URL;
        }
        else{
            this.mainApiURLfromEnv  = process.env.VUE_APP_MAIN_API_URL;
        }
        return this.mainApiURLfromEnv;
    }

    getPayApiURL(){
        if(process.env.VUE_APP_DEV_MODE == "true"){
            this.payApiURLfromEnv  = process.env.VUE_APP_PAY_DEV_API_URL;
        }
        else{
            this.payApiURLfromEnv  = process.env.VUE_APP_PAY_API_URL;
        }
        return this.payApiURLfromEnv;
    }
}