<template>
    <header>
        <div class="header-transparent "> <!-- <div class="mt-40 header-transparrent ">-->
            <div class="main-header">
                <div class="container">
                    <div class="row align-items-center justify-content-center">
                        <!-- Logo -->
                        <div class="col-xl-2 col-lg-4 col-md-6 col-5 pt-20 pb-20 ">
                            <div class="logo">
                                <a href="/"><img src="@/assets/img/header/logo.png" alt="" class="img-fluid"></a>
                            </div>
                        </div><!--
                        <div class="col-xl-10 col-lg-10 col-md-10">
                            
                        </div>-->
                        <!-- Mobile Menu ->
                        <div class="col-12">
                            <div class="mobile_menu d-block d-lg-none"></div>
                        </div>-->
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
export default {
  name: 'HeaderPage',
}
</script>

<style scoped>
</style>