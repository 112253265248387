<template>
  <HeaderPage/>
  <main style="font-family: Syne;">
    <HomePage/>
    <AboutMoovePage/>
    <!--<MessagePage/>-->
    <CadFormPage/>
    <PresentationPage/>
    <!--<DownloadMoovePage/>-->
    <FooterMoovePage/>
  </main>
</template>

<script>
import HeaderPage from './pages/HeaderPage.vue'
import HomePage from './pages/HomePage.vue'
import AboutMoovePage from './pages/AboutMoovePage.vue'
//import MessagePage from './pages/MessagePage.vue'
//import PricePage from './pages/PricePage.vue'
import CadFormPage from './pages/CadFormPage.vue'
import PresentationPage from './pages/PresentationPage.vue'
//import DownloadMoovePage from './pages/DownloadMoovePage.vue'
import FooterMoovePage from './pages/FooterMoovePage.vue'

import '@/assets/css/bootstrap.min.css'
import '@/assets/css/owl.carousel.min.css'
import '@/assets/css/flaticon.css'
import '@/assets/css/slicknav.css'
import '@/assets/css/animate.min.css'
import '@/assets/css/magnific-popup.css'
import '@/assets/css/fontawesome-all.min.css'
import '@/assets/css/themify-icons.css'
import '@/assets/css/slick.css'
import '@/assets/css/nice-select.css'
import '@/assets/css/style.css'
import '@/assets/css/main.css'

export default {
  name: 'LpBase',
  components: {
    HeaderPage,
    HomePage,
    AboutMoovePage,
    //MessagePage,    
    CadFormPage,
    PresentationPage,
    //DownloadMoovePage,
    FooterMoovePage
  },
  methods: {
    hideDocumentScroll(){
      document.documentElement.style.overflow ='hidden';
    },
    showDocumentScroll(){
      document.documentElement.style.overflow ='auto';
    },
  },

  mounted() {
    document.title='Sensorial Moove Trainer';
  }
}
</script>