<template>
    <div class="modal fade show" id="modal-billing" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style="opacity:1;display:block">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content"  :style=" setModalWindowSize" ref="modalWindow">
                <div class="modal-header">
                    <button type="button" class="close d-flex align-items-center justify-content-center" data-dismiss="modal" aria-label="Close" @click=closeModal($emit)>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="row">
                    <div class="col-md">
                        <div class="modal-body p-0">
                            <h3 class="mb-1">Vamos atualizar seus dados?</h3>
                            <p class="mb-4"><i class="fa fa-question-circle" data-tooltip="" data-title=""></i> Precisamos desses dados para emitir a sua Nota Fiscal.</p>
                            <div class="alert alert-warning" v-if="globalAlert" role="alert" ref="modalGlobalAlert" style="margin-top: 15px; margin-bottom: 15px;">
                                {{ globalAlert }}
                            </div>
                            <form class="forms " @submit.prevent="submit" method="post" id="frm-billing" novalidate="novalidate">
                                

                                <div class="form-group ">
                                    <label for="document"><span class="required-label">*</span>CPF: <i class="fa fa-question-circle" data-tooltip="" title="Pedimos o CPF para emissão da sua Nota Fiscal"></i></label>
                                    <input name="document" type="text" class="form-control" required v-model="userData.document" v-mask="'###.###.###-##'" autocomplete="off" maxlength="14">
                                    <div class="msg-validate">{{validationErrorBag.errorDocument}}</div>
                                </div>

                                <div class="form-group ">
                                    <div style=" float: left;  display: flex; ">
                                        <label for="zip"><span class="required-label" >*</span>CEP: </label>
                                        <ClipLoader :loading="loadingSpin" :color="'#e8e221'" :size="'20px'" style="    margin-left: 5px;"/>
                                    </div>                                    
                                    <input name="zip" type="text" class="form-control" required v-model="userAddress.zip" v-mask="'#####-###'" autocomplete="off" maxlength="10" @blur="getAddressByCep()">
                                    <div class="msg-validate">{{validationErrorBag.errorZip}}</div>
                                </div>

                                <div class="form-group ">
                                    <label for="address"><span class="required-label">*</span>Endereço:</label>
                                    <input name="address" type="text" class="form-control" required v-model="userAddress.address">
                                    <div class="msg-validate">{{validationErrorBag.errorAddress}}</div>
                                </div>

                                <div class="form-group ">
                                    <label for="number"><span class="required-label">*</span>Número:</label>
                                    <input name="number" type="text" class="form-control" required v-model="userAddress.number">
                                    <div class="msg-validate">{{validationErrorBag.errorNumber}}</div>
                                </div>

                                <div class="form-group ">
                                    <label for="complement">Complemento:</label>
                                    <input name="complement" type="text" class="form-control" required v-model="userAddress.complement">
                                    <div class="msg-validate">{{validationErrorBag.errorComplement}}</div>
                                </div>

                                <div class="form-group  ">
                                    <label for="neighborhood">Bairro:</label>
                                    <input name="neighborhood" type="text" class="form-control" required v-model="userAddress.neighborhood">
                                    <div class="msg-validate">{{validationErrorBag.errorNeighborhood}}</div>
                                </div>
                                <div class="form-group  ">
                                    <label for="city">Cidade:</label>
                                    <input name="city" type="text" class="form-control" required v-model="userAddress.city">
                                    <div class="msg-validate">{{validationErrorBag.errorCity}}</div>
                                </div>
                                <div class="form-group  ">
                                    <label for="state">UF:</label>
                                    <input name="state" type="text" class="form-control" required v-model="userAddress.state">
                                    <div class="msg-validate">{{validationErrorBag.errorState}}</div>
                                </div>

                                <!-- 
                                
                                <div class="form-group">
                                    <label for="city">Cidade:</label>
                                    <input name="city" type="text" class="form-control" required="" v-model="userAddress.city">
                                    <div class="msg-validate"></div>
                                </div>

                                <div class="form-group">
                                    <label for="state">UF:</label>
                                    <input name="state" type="text" class="form-control" required="" v-model="userAddress.state">
                                    <div class="msg-validate"></div>
                                </div> 

                                <div class="form-group">
                                    <label for="neighborhood">Bairro:</label>
                                    <input name="neighborhood" type="text" class="form-control" required="" v-model="userAddress.neighborhood">
                                    <div class="msg-validate"></div>
                                </div>

                                -->
                                <div class="form-group ">
                                    <label for="coupon">Cupom:</label>
                                    <input name="coupon" type="text" class="form-control coupon" v-model="userData.coupon">
                                    <div class="msg-validate">{{validationErrorBag.errorCoupon}}</div>
                                </div>
                                

                                <div class="form-group">
                                    <button class="btn card-btn1 w-100 mt-2" id="btn-billing-update" :style="formButtonStyle" :disabled="isSubButtonDisabled" @click="submit()" type="button" style="line-height: 0px;">
                                        <ClipLoader :loading="!isSubButtonDisabled" :color="'#e8e221'" :size="'35px'"  id="clipspin1" v-show="true" v-if="isSubButtonDisabled" style="margin-top: -15px;"/>
                                        <span v-if="!isSubButtonDisabled">IR PARA O PAGAMENTO</span>
                                    </button>
                                </div>                                                        
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {mask} from 'vue-the-mask'


import { ClipLoader } from 'vue3-spinner';

import { RegisterApi } from '@/functions/RegisterApi.js';
import { ZipCodeApi } from '@/functions/ZipCodeApi.js';

export default{
    name: "UserData",
    components: {
        ClipLoader
    },

    directives: {
        mask
    },

    created: function(){
        if(process.env.VUE_APP_DEFAULT_INPUTS_VALUES != "true"){
            let iteratedObject = this.userData;
            Object.keys(iteratedObject).map(function(k){                 
                iteratedObject[k] = '';                
            })

            iteratedObject = this.userAddress;
            Object.keys(iteratedObject).map(function(k){                 
                iteratedObject[k] = '';                
            })
        }
    },
    
    data: function(){
        return {

            userData: {
                document: '911.128.671-71',
                coupon: '',
            },

            userAddress : {
                zip: '14097010',
                address: '',
                number: '95',
                complement: '',
                city: '',
                state: '',
                neighborhood: '',
            },
            
            validationErrorBag: {
                errorCoupon: '',
                errorDocument: '',
                errorZip: '',
                errorAddress: '',
                errorNumber: '',
                errorComplement: '',
                errorCity: '',
                errorState: '',
                errorNeighborhood: ''
            },

            loadingSpin: false,
            isSubButtonDisabled: false,
            formButtonStyle: '',
            globalAlert: '',
            registerApi: new RegisterApi(),
            modalSize: window.innerHeight * 0.8,
        }
    },

    methods: {
        
        async submit(){

            console.log('submit');
            this.globalAlert= '';
            this.toggleButtonBehavior();
 
            if(this.validation(this.registerApi.validateRegister(this.userData, this.userAddress, true))){
                console.log("validated");

                if((await this.dataStore())){
                    console.log("stored trainer data");
                    this.$parent.subscription(this.userData.coupon);
                    this.$emit('close');
                    this.$parent.closeUserDataModal();
                }
            }
            else{
                this.globalAlert = 'Corrija os campos abaixo';                
            }
            this.toggleButtonBehavior();
            this.focusGlobalAlert();
        },


        validation: function(evaluation){
            console.log("validation");
            //console.log(evaluation._errorBag);

            this.registerApi.pupulateValidatedErrorBag(this, evaluation._errorBag);
            //console.log(this.validationErrorBag);

            return evaluation.isValid;
        },

        async dataStore(){
            console.log("store trainer data");

            let success;
            var trainerDataStoreResponse = await this.registerApi.doDataStore(this.userData, this.userAddress, this.$parent.userId, this.$parent.userToken);

            //console.log(trainerDataStoreResponse);
            if(trainerDataStoreResponse._success === false){
                this.globalAlert = trainerDataStoreResponse._error;
            }

            success = trainerDataStoreResponse._success;
            return success;            
        },
        
        async getAddressByCep(){
            this.toggleSpinnerShow();

            let zipCodeApi = new ZipCodeApi();
            let zipResponse = await zipCodeApi.doGetAddressByCep(this.userAddress.zip);

            //console.log(zipResponse);
            zipCodeApi.buildAddressViewObject(zipResponse, this);
            this.toggleSpinnerShow();            
        },

        
        toggleButtonShowLoader: function(){            
            this.isSubButtonDisabled = !this.isSubButtonDisabled;
        },

        toggleSpinnerShow: function(){
            this.loadingSpin = !this.loadingSpin;
        },

        toggleButtonStyle: function () {
            if(this.isSubButtonDisabled){
                this.formButtonStyle = {'pointer-events' : 'none'};                
            }
            else{
                this.formButtonStyle = '';                
            }
        },

        toggleButtonBehavior: function () {
            this.toggleButtonShowLoader();
            this.toggleButtonStyle();
        },

        closeModal: function(emitfunc){            
            emitfunc('close');

            this.$parent.closeUserDataModal();
        },

        focusGlobalAlert: function(){
            if(this.globalAlert != ''){
                this.$nextTick(() => {                    
                    this.$refs.modalGlobalAlert.scrollIntoView();
                });
            }
            console.log("focus");
        },

        windowSizetHandler(){
            this.modalSize = window.innerHeight * 0.8;
            this.$refs.modalWindow.style.maxSize = '${this.modalSize}px';
            /*console.log(this.modalSize);
            console.log(window.innerHeight);*/
        }        
    },

    computed: {

        setModalWindowSize() {
            /*console.log(this.modalSize);
            console.log(window.innerHeight);*/
            return {
                'max-height': this.modalSize+'px',
                'overflow-y': 'auto'
            }
        }
    },

    mounted() {
        window.addEventListener("resize", this.windowSizetHandler);
    },

    unmounted() {
        window.removeEventListener("resize", this.windowSizetHandler);
    },
}
</script>