<template>
    <div class="slider-area ">
        <div class="">
            <div class=" slider-height bg-hero">
                <div class="container containerTopPad text-white">
                    <div class="row sqquared">
                        <div class="col-lg-6 col-xl-6 firsthalf">
                            <div class="d-flex flex-column justify-content-between h-100">
                                <div class="row firsthalfL1">                                
                                    <div class="col">
                                        <p class="textstyleSize1 textBold fontSyne" data-animation="fadeInUp" data-delay="1.5s" >
                                            Para você, que sempre se preocupou<br> com o desenvolvimento da
                                            <br><span class="textstyleHighlightedMagenta">COGNIÇÃO DE SEUS ATLETAS</span><br>
                                            a Sensorial está lançando o                                    
                                        </p>
                                    </div>
                                </div>
                                <div class="row firsthalfL2">
                                    <div class="col">
                                        <img src="@/assets/img/home/logo-trainer.png" alt="logo Sensorial Moove Trainer" class="img-fluid mb-15">
                                    </div>
                                </div>
                                <div class="row firsthalfL3">
                                    <div class="col">
                                        <p class="textstyleSize2" data-animation="fadeInUp" data-delay="1.6s" >
                                                    Desenvolva o cérebro dos seus atletas <br> 
                                                    de maneira eficiente e gamificada.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                        <div class="col-lg-6 col-xl-6 secondhalf">
                            <div class="d-flex flex-column">
                                <div class="row secondhalfL1">
                                    <div class="col">                                        
                                        <div class="hero__img  f-right" data-animation="fadeInRight" data-delay="1.7s">
                                            <img src="@/assets/img/mosaico-banner.png" alt="" class="img-fluid">
                                        </div>
                                    </div>
                                </div>
                                <div class="row secondhalfL2">
                                    <div class="col">                                         
                                        <div class="d-flex flex-row-reverse">
                                            <div class="row">
                                                <p class="textstyleSize3" data-animation="fadeInRight" data-delay="1.6s" >
                                                    <span style="font-weight: bold">Desenvolvido para tablets</span> 
                                                    <br>*Android 10 ou superior 
                                                    <br>IOS 12 ou superior
                                                </p>                                            
                                            </div>
                                        </div>
                                    </div>                                
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>

        </div>
    </div>
</template>

<script>

export default {
  name: 'HomePage',

  methods: {
        handleScroll(){            
            document.title='Sensorial Moove Trainer';
        }
    },

    mounted: function () {
        let pageElement  = document.querySelector('.slider-area');        
        pageElement.addEventListener('mouseenter', this.handleScroll, {once : false});
    },
    unmounted: function () {
        let pageElement  = document.querySelector('.slider-area');        
        pageElement.removeEventListener('mouseenter', this.handleScroll, {once : false});
    }
}
</script>

<style scoped>

.textBold{
    font-weight: bold;
}

.containerTopPad{
    padding-top: 10rem;
}

.textstyleHighlightedMagenta{
    color: #fff;
    background-color: #ea005f;
    border-radius: 30px;
    padding: 5px;
    font-size: 1.1em;
    font-weight: bold;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
}

p{
    /*font-family: Syne;*/
    color: inherit;
}

@media screen and (max-width: 575px){
    .textstyleSize1{
        font-size: 1.3em;
        line-height: 1.5;
    }

    .textstyleSize2{
        font-size: 1.1em;
    }

    .textstyleSize3{
        font-size: 0.9em;
    }
}

@media screen and (min-width: 576px) and (max-width: 767px){
    .textstyleSize1{
        font-size: 1.3em;
    }

    .textstyleSize2{
        font-size: 1.3em;
    }

    .textstyleSize3{
        font-size: 1.0em;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px){
    .textstyleSize1{
        font-size: 1.3em;
        line-height: 1.442em;
    }

    .textstyleSize2{
        font-size: 1.25em;
    }

    .textstyleSize3{
        font-size: 1.0em;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px){
    .textstyleSize1{
        font-size: 1.6em;
        line-height: 1.4em;
    }

    .textstyleSize2{
        font-size: 1.4em;
    }

    .textstyleSize3{
        font-size: 1.0em;
    }
}

@media screen and (min-width: 1200px){
    .textstyleSize1{
        font-size: 2em;
        line-height: 1.4em;
    }

    .textstyleSize2{
        font-size: 1.5em;
    }

    .textstyleSize3{
        font-size: 1.25em;
    }
}


</style>