import { UrlHelper } from "./UrlHelper.js";

export class SubscriptionApi{
    
    urlHelper = new UrlHelper();    
    mainApiURL= this.urlHelper.getMainApiURL();
    payApiURL = this.urlHelper.getPayApiURL();

    _installments = 1;
    _paymentLink = '';

    async generatePaymentLink(loginData){
        console.log("generatepaymentlink");
        //console.log(loginData.cupon+'asd');
        let _success = false;
        //let _userId = 0;
        //let _coupon = 'asdasd';
        let _error = '';
        let _paymentLink = '';
        /*
        this._paymentLink = 'https://cielolink.com.br/3Vm33Dk';
        _paymentLink = this._paymentLink;
        _success = true;
        //this.doAfterLinkGenerated();
        */
        //await fetch(this.payApiURL+'/api/v1/subscription/trainer/web/subscribe', {
        await fetch(this.mainApiURL+'/api/v1/subscriptions/trainer/web', {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${loginData._userToken}`
            },
            body: JSON.stringify({ 
                "user_id": loginData._userId,
                "installments": this._installments,
                "coupon": loginData._coupon,
            })
        })
        .then(async (response) => {
            //console.log("then");
            var data = await response.json();

            if(response.status == 200){
                //this._paymentLink = data.payment_link;
                
                _paymentLink = data.data.payment_link;
        
                if(_paymentLink){
                    //this.doAfterLinkGenerated();
                    _success = true;
                    _error = data.message;
                    //console.log("200");
                }
                else{                                        
                    throw 'Erro ao gerar link de pagamento, tente novamente mais tarde';
                }
            }
            else{
                if(response.coupon){                    
                    throw 'Cupom inválido';
                }
                else{                    
                    throw 'Erro ao fazer assinatura, tente novamente mais tarde';
                }                
            }
        })
        .catch(error => {
            //console.log("catch");                        
            _error = error;
            console.log(error)
        })

        console.log("returned");
        return {_success, _paymentLink, _error};
    }
    
    doAfterLinkGenerated(paymentLink){
        console.log("openlink");
        //console.log(paymentLink);
        
        window.open(paymentLink, '_blank')
    }
}