<template>
  <LpBase/>
</template>

<script>
import LpBase from './components/LpBase.vue'

export default {
  name: 'App',
  components: {
    LpBase
  }
}
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
} */
</style>
