<template>
    <div class="say-something-aera pt-10 pb-10 fix">
            <div class="container">
                <div class="row justify-content-between align-items-center">
                    <div class="col">
                        <h1 class="text-align-center marginTop1Em textstyleSize5" style=";">Veja o que você pode fazer com o Moove Trainer</h1>
                    </div>
                </div>
                <div class="row mt-40 mb-40">
                    <div class="col">
                        <div style="width:100%;max-width:950px;overflow:hidden;padding: 0;margin: 0 auto;">
                            <video style="width: 100%;" src="@/assets/video/Mooveapp.webm" controls autoplay muted></video>
                        </div>
                    </div>
                </div>            
            </div>
        </div>
</template>

<script>
export default {
  name: 'PresentationPage',

  methods: {
        handleScroll(){            
            document.title='Sensorial Moove Trainer | Apresentação';
        }
    },
   
    mounted: function () {
        let pageElement  = document.querySelector('.say-something-aera');
        pageElement.addEventListener('mouseenter', this.handleScroll, {once : false});
    },
    unmounted: function () {
        let pageElement  = document.querySelector('.say-something-aera');
        pageElement.removeEventListener('mouseenter', this.handleScroll, {once : false});
    }
}
</script>

<style scoped>
.marginTop1Em{
    
    margin-top: 1em;
}

@media screen and (max-width: 575px){
    .textstyleSize5{
        font-size: 1.0em !important;
    }

   
}

@media screen and (min-width: 576px) and (max-width: 767px){
    .textstyleSize5{
        font-size: 1.5em !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px){
    .textstyleSize5{
        font-size: 1.8em !important;
    }


}

@media screen and (min-width: 992px) and (max-width: 1199px){
    .textstyleSize5{
        font-size: 2.5em !important;
    }

 
}

@media screen and (min-width: 1200px){
    .textstyleSize5{
        font-size: 3em !important;
    }

}
</style>